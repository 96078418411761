.steps {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .step {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    line-height: 30px;
    text-align: center;
    color: #fff;
  }
  
  .step.completed {
    background-color: #f90; /* Set for completed steps */
  }
  
  .step + .step {
    margin-left: 10px;
  }
  


  /*pagination */

  .pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
  color: #e4ae1a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  z-index: 2;
  color: #000000;
  cursor: default;
  background-color: #F9BD15;
  border-color: #F9BD15;
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}